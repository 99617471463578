.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fdfdfd;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.margin{
  margin: 10px;
}

.positiontopright{
  position: absolute;
  top: 30px;
  right: 50px;
  color: rgb(250, 18, 250);
}

.color-red{
  color: red;
}

.color-purple{
  color: rgb(46, 130, 240);
}

.text-12{
  font-size: 18px;
}

.positiontopright10{
  position: absolute;
  top: 60px;
  right: 50px;
  color: rgb(250, 18, 250);
}